import React from "react";
import Particle from "../Particle";
import { Container, Row, Col } from "react-bootstrap";

function Terms() {
  return (
    <Container fluid className="terms-section" id="terms">
      <Particle />
      <Container>
        <Row>
          <Col md={12} className="terms-content">
            <h1>Правила покупки и использования Discord/Telegram ботов</h1>
            <p>
              1.1. Каждый приобретенный продукт (Далее - бот/боты) продается в одни руки.
            </p>
            <p>
              1.2. Любые попытки обмана разработчика или введения менеджера в заблуждение наказываются отключением бота/ботов от хоста, и возврат средств невозможен.
            </p>
            <p>
              1.3. Разработчик и менеджер не несут ответственности за любые противозаконные действия, совершаемые покупателем с использованием товаров, приобретенных в магазине.
            </p>
            <p>
              1.4. Оплачивая бота/ботов покупатель автоматически соглашается с правилами покупки и использования.
            </p>
            <p>
              1.5. Деньги за бота/ботов не возвращаются.
            </p>
            <p>
              1.6. Неознакомление с правилами не снимает ответственности с покупателя.
            </p>
            <p>
              1.7. Разработчик не несёт ответственности за утерю/распространение покупателем токенов, ключей и другой конфиденциальной информации.
            </p>
            <p>
              1.8. Гарантии на работу ботов и условия обслуживания предоставляются по усмотрению разработчика.
            </p>
            <p>
              1.9. Менеджер доступен для урегулирования проблем и вопросов. Покупатели могут предоставить обратную связь для улучшения сервиса.
              Связаться можно тут: Discord - (anilopq | id: 941699570742722590)
            </p>
            <p>
              1.10. Обновления и новые версии ботов могут быть предоставлены. Условия предоставления обновлений зависят от заданного технического задания (Далее - ТЗ).
            </p>
            <p>
              1.11. Покупатели обязаны соблюдать правила использования ботов в соответствии с политиками платформ (Discord, Telegram и т. д.).
            </p>
            <p>
              1.12. Правила могут дополняться/изменяться в любой момент на усмотрение разработчика.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Terms;