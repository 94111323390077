import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import chatify from "../../Assets/Projects/chatify.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
import verify from "../../Assets/Projects/verify.png";
import Room from "../../Assets/Projects/Screenshot 2023-10-18 145822.png";
import Moderation from "../../Assets/Projects/Moderation.png";
import Events from "../../Assets/Projects/Events.png";
import Banner from "../../Assets/Projects/Banner.png";
import zahodi from "../../Assets/Projects/zahodi.png";
import anti from "../../Assets/Projects/anticrash.png";
import logs from "../../Assets/Projects/logs.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Мои последние <strong className="purple">работы. </strong>
        </h1>
        <p style={{ color: "white" }}>
          Здесь вы можете посмотреть над чем я работал в последнее время.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Creative Hiroshi"
              titleServer="Hiroshi"
              description="Бот созданный для управления зоной отдыха на сервере Hiroshi."
              ghLink="https://discord.gg/hiroshi"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Afisha Hiroshi"
              titleServer="Hiroshi"
              description="Бот созданный для управления созданием афиш о просмотре фильмов/сериалов/аниме, а так же озвучки на сервере Hiroshi."
              ghLink="https://discord.gg/hiroshi"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={verify}
              isBlog={false}
              title="Verify"
              titleServer="Yukiry"
              description="Бот для верификации пользователей на сервере Yukiry."
              ghLink="https://discord.gg/yukiry"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Room}
              isBlog={false}
              title="YukiryRoom"
              titleServer="Yukiry"
              description="Бот для создания и управления приватными комнатами на сервере Yukiry."
              ghLink="https://discord.gg/yukiry"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Moderation}
              isBlog={false}
              title="Moderation"
              titleServer="Yukiry"
              description="Бот для модерации сервера на публичных серверах (выдача наказаний и тд)."
              ghLink="https://discord.gg/yukiry"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Events}
              isBlog={false}
              title="Events"
              titleServer="Yukiry"
              description="Бот для проведения мини-мероприятий (сломанный телефон, соло и т д) на публичных серверах."
              ghLink="https://discord.gg/yukiry"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Banner}
              isBlog={false}
              title="Banner бот"
              titleServer="Yukiry"
              description="Бот для установки баннера сервера (формат: gif, png) на публичных серверах."
              ghLink="https://discord.gg/yukiry"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={anti}
              isBlog={false}
              title="Anticrash"
              titleServer="Hiroshi"
              description="Бот для защиты сервера от сноса/краша."
              ghLink="https://discord.gg/hiroshi"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={logs}
              isBlog={false}
              title="Logs"
              titleServer="Hiroshi"
              description="Бот логов по всему серверу по всем событиям(стримы/войсы/чаты/итд)."
              ghLink="https://discord.gg/hiroshi"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={zahodi}
              isBlog={false}
              title="Бот для пингов новичков"
              titleServer="Hiroshi"
              description="Бот для пингов новичков с целью призвать их заходить в прихожки"
              ghLink="https://discord.gg/hiroshi"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
