import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import { FaDiscord, FaTelegram } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              НУ ЧТО Ж ПОРА<span className="purple"> ПРЕДСТАВИТЬСЯ  </span>
            </h1>
            <p className="home-about-body">
              Мой жизненный путь решил что мне нужно заниматься программированием,
               по причине того, что мне это нравится, поэтому я здесь!
              <br />
              <br />Я спокойно делаю <span className="purple">АБСОЛЮТНО</span> любых ботов для DS/TG.
               На таких языках как
              <i>
                <b className="purple"> Javascript, Python. </b>
              </i>
              <br />
              <br />
              Моя сфера интересов - строительство качественных&nbsp;
              <i>
                <b className="purple">ботов, сайтов и любых Desktop-продуктов. </b> 
              </i>
              <br />
              <br />
              С большим удовольствием я применяю <b className="purple">Node.js</b> и
              <i>
                <b className="purple">
                  {" "}
                  Современные библиотеки и фреймворки Javascript
                </b>
              </i>
              &nbsp; так же нравятся
              <i>
                <b className="purple"> React.js и Next.js.</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Найди меня здесь!</h1>
            <p>
              Не стесняйся <span className="purple">пиши </span>мне
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://t.me/ETERNXLXTY"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaTelegram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://discord.gg/RAa7Dtkqa6"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaDiscord />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
