import React from "react";
import PriceCard from "./PriceCards";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function Price() {
    return (
      <Container fluid className="price-section">
        <Particle />
        <Container>
          <h1 className="price-heading">
            Наши <strong className="purple">цены. </strong>
          </h1>
          <p style={{ color: "white" }}>
            Здесь вы можете ознакомиться с ценами на наших ботов.
          </p>
          <marquee scrollamount="15" style={{ color: "purple" }}>
            ЕСЛИ ВАМ НУЖНА УСКОРЕННАЯ РАЗРАБОТКА БОТА/ОВ - ТО ЗА КАЖДОГО БОТА/ОВ ПРОИЗВОДИТСЯ ДОПЛАТА!
            ТОЧНЫЕ ЦЕНЫ УЗНАВАТЬ В ЛС!
          </marquee>
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            <Col md={4} className="price-card">
              <PriceCard
                botName="Экономика"
                price="3200р"
              />
            </Col>
  
            <Col md={4} className="price-card">
              <PriceCard
                botName="Верификация"
                price="2000р"
              />
            </Col>

            <Col md={4} className="price-card">
              <PriceCard
                botName="Модерация"
                price="2000р"
              />
            </Col>


            <Col md={4} className="price-card">
              <PriceCard
                botName="Стафф бот"
                price="1500р"
              />
            </Col>

            
            <Col md={4} className="price-card">
              <PriceCard
                botName="Бот баннер (gif/png)"
                price="1000р"
              />
            </Col>


            <Col md={4} className="price-card">
              <PriceCard
                botName="Бот приваток"
                price="2500р"
              />
            </Col>

            <Col md={4} className="price-card">
              <PriceCard
                botName="Креатив бот"
                price="5500р"
              />
            </Col>

            <Col md={4} className="price-card">
              <PriceCard
                botName="Бот ивентов"
                price="2000р"
              />
            </Col>

            <Col md={4} className="price-card">
              <PriceCard
                botName="Антикраш"
                price="3000р"
              />
            </Col>

            <Col md={4} className="price-card">
              <PriceCard
                botName="Логи"
                price="1000р"
              />
            </Col>

            <Col md={4} className="price-card">
              <PriceCard
                botName="Бот для пингов новичков"
                price="1000р"
              />
            </Col>

            <Col md={4} className="price-card">
              <PriceCard
                botName="Фулл пак"
                price="10000р"
              />
            </Col>

            <Col md={4} className="price-card">
              <PriceCard
                botName="Бот по ТЗ"
                price="Договорная"
              />
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
  
  export default Price;