import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Разработчик Discord/Telegram ботов",
          "Ламповая няша",
          "Любитель печенек",
          "Качество - это важно!",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
