import React from "react";
import Card from "react-bootstrap/Card";

function PriceCard(props) {
  return (
    <Card className="price-card-view">
      <Card.Body>
        <Card.Title>{props.botName}</Card.Title>
        <Card.Text style={{ textAlign: "center" }}>
          Цена: {props.price}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}export default PriceCard;